import Vue from "vue";
import Router from "vue-router";
import AppLayout from "bh-mod/layouts/App";
import Splash from "@/layouts/Splash";
import AuthLayout from 'bh-mod/layouts/Auth'
import store from "@/store";
import { VueAppMiddleware } from "bh-mod";

Vue.use(Router);

const router = new Router({
	base: process.env.BASE_URL,
	mode: "history",
	scrollBehavior() {
		return { x: 0, y: 0 };
	},
	routes: [
		{
			path: "/",
			redirect: "/email",
			component: AppLayout,
			meta: {
				instanceRequired: true,
				auth: true,
				hidden: true,
			},
			children: [
				{
					path: "/email",
					meta: {
						title: "Email Broadcasts",
						dataRequired: true,
						sidebar: true,
					},
					component: () => import("./views/email"),
				},
				{
					path: "/email/:id",
					meta: {
						title: "Email Details",
						dataRequired: true,
						sidebar: true,
					},
					component: () => import("./views/email/details.vue"),
				},
				{
					path: "/survey/",
					meta: {
						title: "Form Surveys",
						dataRequired: true,
						sidebar: true,
					},
					component: () => import("./views/survey"),
				},
				{
					path: "/survey/:id",
					meta: {
						title: "Survey Details",
						dataRequired: true,
						sidebar: true,
					},
					component: () => import("./views/survey/details.vue"),
				},
				{
					path: "/agentCommission",
					meta: {
						title: "Agent Commission Report",
						dataRequired: true,
						sidebar: true,
					},
					component: () => import("./views/agentCommission"),
				},
				{
					path: "/pipeline",
					meta: {
						title: "Pipeline Report",
						dataRequired: true,
						sidebar: true,
					},
					component: () => import("./views/pipeline"),
				},
				{
					path: "/lead",
					meta: {
						title: "Lead Report",
						dataRequired: true,
						sidebar: true,
					},
					component: () => import("./views/lead"),
				},
				{
					path: "/lead/details",
					meta: {
						title: "Lead Details",
						dataRequired: true,
						sidebar: true,
					},
					component: () => import("./views/lead/details.vue"),
				},
				{
					path: "/lead/campaignDetails",
					meta: {
						title: "Lead Campaign Details",
						dataRequired: true,
						sidebar: true,
					},
					component: () => import("./views/lead/campaignDetails.vue"),
				},
				{
					path: "/pipeline/details",
					meta: {
						title: "Pipeline Details",
						dataRequired: true,
						sidebar: true,
					},
					component: () => import("./views/pipeline/details.vue"),
				},
				{
					path: "/inventory",
					meta: {
						title: "Inventory Report",
						dataRequired: true,
						sidebar: true,
					},
					component: () => import("./views/inventory"),
				},
				{
					path: "/inventory/details",
					meta: {
						title: "Inventory Details",
						dataRequired: true,
						sidebar: true,
					},
					component: () => import("./views/inventory/details.vue"),
				},
				{
					path: "/sales",
					meta: {
						title: "Sales Reports",
						dataRequired: true,
						sidebar: true,
					},
					component: () => import("./views/sales"),
				},
				{
					path: "/sales/details",
					meta: {
						title: "Sales Details",
						dataRequired: true,
						sidebar: true,
					},
					component: () => import("./views/sales/details.vue"),
				},
				{
					path: "/website",
					meta: {
						title: "Website Reports",
						dataRequired: true,
						sidebar: true,
					},
					component: () => import("./views/website"),
				},
				{
					path: "/website/details",
					meta: {
						title: "Website Details",
						dataRequired: true,
						sidebar: true,
					},
					component: () => import("./views/website/details.vue"),
				},
				{
					path: "/notifications",
					meta: {
						title: "All Notifications",
						sidebar: true,
						dataRequired: true
					},
					component: () => import('bh-mod/components/layout/TopBar/Actions/notifications.vue'),
				},
			],
		},
		{
			path: "/setup",
			component: Splash,
			meta: { hidden: true, splash: true },
			children: [
				{
					path: "/setup",
					meta: {
						title: "Setup Inventory App",
						splashScreen: true,
					},
					component: () => import("./views/setup"),
				},
			],
		},
		{
			path: '/404',
			component: Splash,
			children: [
				{
					path: '/404',
					meta: {
						title: 'Error 404',
					},
					component: () => import('bh-mod/components/common/404.vue'),
				}
			]
		},
		{
			path: '/unauthorized',
			component: AuthLayout,
			children: [
				{
					path: '/unauthorized',
					meta: {
						title: 'Unauthorized',
						unauthorized: true
					},
					component: () => import('bh-mod/components/common/unauthorized.vue'),
				}
			]
		},
		// Other routes...
		{
			// Catch-all route: This will match any invalid route
			path: '*',
			redirect: '/404'
		}
	],
});

let hiveRequested = false;
router.beforeEach(async (to, from, next) => {
	let dataRequired = to.matched.some((record) => record.meta.dataRequired);
	let splashScreen = to.matched.some((record) => record.meta.splashScreen);
	let highriseOnly = to.matched.some((record) => record.meta.highriseOnly);
	let unauthorized = to.matched.some(record => record.meta.unauthorized)
	let error = false

	if (!hiveRequested) {
		let response = await VueAppMiddleware(to, store, 'reports')
		error = response.parsed
		hiveRequested = true;
	}

	if (error) {
		if (error === 'User does not have required permission!') {
			return next('/unauthorized')
		}
		return console.error('ERROR', error)
	} else {
		if (unauthorized) {
			return next();
		}
		if (highriseOnly && store.state.instance.productType !== "highrise") {
			finalRouter(to, next);
		}
		let showIntro =
			store.state.theApp &&
			store.state.theApp.media &&
			store.state.theApp.media.options &&
			store.state.theApp.media.options.showIntro;
		if (typeof showIntro !== "boolean") showIntro = true;

		if (splashScreen) {
			if (!showIntro) {
				finalRouter(to, next);
			}
			if (
				store.state.reports.allSettings &&
				store.state.reports.allSettings.userApp &&
				store.state.reports.allSettings.userApp.options &&
				store.state.reports.allSettings.userApp.options.seenIntro
			) {
				finalRouter(to, next);
			}
		}
		if (dataRequired) {
			if (
				showIntro &&
				!store.state.reports.getStarted &&
				(!store.state.reports.allSettings.userApp ||
					!store.state.reports.allSettings.userApp.options ||
					!store.state.reports.allSettings.userApp.options.seenIntro)
			) {
				return next("/setup");
			}
		}
	}
	store.commit("PAGE_BUTTONS", []);

	finalRouter(to, next);
});

const minAccessRoutes = ["/lead", "/pipeline", "/sales"];
const medAccessRouters = ["/email", "/lead", "/pipeline", "/sales"];

function finalRouter(to, next) {
	if (to.path.startsWith("/setup")) {
		return next();
	}
	if (to.path.startsWith("/agentCommission") && !store.getters?.isWorksheetActive) {
		// restricted reservations page to highrise premium package and active worksheet feature only
		return next("/");
	}
	if (
		store.state.$p <= 10 &&
		!minAccessRoutes.some((path) => to.path.startsWith(path))
	) {
		return next("/lead");
	} else if (
		store.state.$p <= 20 &&
		!medAccessRouters.some((path) => to.path.startsWith(path))
	) {
		return next("/email");
	} else if (store.state.instance && store.state.instance.package && store.state.instance.package.name && store.state.instance.package.name.toLowerCase().includes('supplementary') && ['/website', '/survey'].some((path) => to.path.startsWith(path))) {
		return next("/email");
	}
	return next();
}

export default router;
