import Vue from "vue";
import Vuex from "vuex";
import { $api, setProp, deleteProp, arrToObj } from "bh-mod";

Vue.use(Vuex);

export default {
	state: {
		crumbs: "Email",
		allSettings: {},
		getStarted: false,
		commissionPayouts: [],
		loading: false,
		maxLeadScore: null,
	},
	mutations: {
		SET_COMMISSION_PAYOUTS(state, data) {
			state.commissionPayouts = data;
		},
		UPDATE_CRUMBS(state, data) {
			state.crumbs = data;
		},
		SET_SETTINGS(state, data) {
			state.allSettings = data;
		},
		SET_PROP: (state, { where, what, del = false }) => {
			if (del) return deleteProp(state, where);

			if (what.status === null) what.status = "bh_lead";
			else if (typeof what.status === "object") what.status = what.status.id;

			if (what.tags && what.tags.length) {
				what.tags = what.tags.map((tag) => {
					if (typeof tag == "object") return tag.id;
					else return tag;
				});
			}
			state.newTime = Date.now();
			setProp(state, where, what);
		},
		SET_APPDATA(state, { maxLeadScore = 0 }) {
			state.maxLeadScore = maxLeadScore
		},
		GET_STARTED: (state) => {
			state.getStarted = true;
		},
		LOADING: (state, bool = !state.loading) => state.loading = bool,
	},
	actions: {
		GET_TIMELINES: async ({ commit }) => {
			commit("LOAD", true);
			$api
				.get("/timelines/:instance/ipm")
				.then((res) => {
					commit("SET_TIMELINES", res.data);
				})
				.catch((err) => {
					console.log(err);
				})
				.finally(() => {
					commit("LOAD", false);
				});
		},
		SET_APPDATA: async ({ commit }, data) => {
			commit("SET_APPDATA", data);
		},
		CLOSE_FORM_DRAWER: ({ commit }, { type, data }) => {
			if (type !== "click")
				commit("SET_PROP", {
					where: ["allForms", data.id],
					what: data,
					del: type === "delete",
				});
			commit("CLOSE_DRAWERS");
		},
		GET_COMMISSION_PAYOUTS({ commit }) {
			return new Promise((resolve, reject) => {
				$api
					.get(`/transactions/:instance/commission-payouts`)
					.then(({ data }) => {
						if (data.length) {
							commit("SET_COMMISSION_PAYOUTS", data);
						}
						return resolve(data);
					})
					.catch((err) => {
						reject(err);
					});
			});
		},
	},
	getters: {
		isWorksheetActive(state, getters, rootState, rootGetters) {
			return rootGetters.hasWorksheetAppAccess && (state.allSettings?.instance?.options?.activeFeatures?.worksheet ?? true);
		}
	},
};
